import React, { useState } from 'react';

function DieRollSelector() {
  const [selectedRoll, setSelectedRoll] = useState(null);

  // Function to handle roll selection
  const handleRollSelect = (roll) => {
    setSelectedRoll(roll);
  };

  return (
    <div>
      <h2>Select a Die Roll</h2>
      <ul>
        {[1, 2, 3, 4, 5, 6].map((roll) => (
          <ul
            key={roll}
            className={selectedRoll === roll ? 'selected' : ''}
            onClick={() => handleRollSelect(roll)}
          >
            {roll}
          </ul>
        ))}
      </ul>
    </div>
  );
}

export default DieRollSelector;
