// Import necessary components from react-router-dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './GameSelection.css';

// Import your page components
import BogglePage from './pages/BogglePage/BogglePage';
import TrivialPursuitPage from './pages/TrivialPursuitPage/TrivialPursuitPage';

const LandingPage = () => {
  return (
    <div className="game-selection-container">
      <h1 className="title">Select a Game to Play</h1>
      <div className="game-options">
        <GameOption title="Trivial Pursuit" description="Test your knowledge." />
        <GameOption title="Boggle" description="Find words in a grid of letters." />
        <GameOption title="Shogi" description="Japanese chess strategy game." />
        <GameOption title="Go" description="Traditional board game of strategy." />
        <GameOption title="Scopa" description="Italian card game." />
      </div>
    </div>
  );
};

const GameOption = ({ title, description }) => {
  return (
    <div className="game-option">
      <h2 className="game-title">{title}</h2>
      <p className="game-description">{description}</p>
      <button className="play-button">Play Now</button>
    </div>
  );
};

// Define your routes
function GamesApp() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/boggle//*" element={<BogglePage />} />
        <Route path="/trivial-pursuit//*" element={<TrivialPursuitPage />} />
      </Routes>
    </Router>
  );
}

export default GamesApp;
