import './App.css';
import GamesApp from './sites/games/GamesApp';

import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';

const WebSocketComponent = () => {
  // WebSocket URL
  const socketUrl = 'ws://localhost:6978/websocket';

  // Options (optional)
  const options = {
    onOpen: () => console.log('WebSocket is open.'),
    shouldReconnect: (closeEvent) => true, // Control reconnection behavior
  };

  // Initialize the WebSocket connection using the hook
  const { lastMessage, readyState, sendMessage } = useWebSocket(socketUrl, options);

  // State to hold user input
  const [inputMessage, setInputMessage] = useState('');

  // Function to handle sending messages
  const handleSendMessage = () => {
    sendMessage(inputMessage);
    setInputMessage('');
  };

  return (
    <div>
      <h1>WebSocket Example</h1>
      <p>WebSocket Status: {readyState}</p>
      <p>Last Received Message: {lastMessage ? lastMessage.data : 'No messages yet'}</p>

      <input
        type="text"
        placeholder="Enter a message"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
}


const GermanApp = () => (
  <div>German</div>
)

function App() {
  const subdomain = window.location.host.split('.')[0];
  switch(subdomain) {
    case 'www':
      return <WebSocketComponent />
    case 'de':
      return <GermanApp />
    case 'gm':
      return <GamesApp />
    default:
      return <WebSocketComponent />
  }
}

export default App;
