// RouterHandler.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useWebSocketContext } from './WebSocketContext';
import GamePage from './GamePage';
import LandingPage from './LandingPage';


const GameView = ({data}) => {
  return (
    <div>
      {data.gameState.setupStage === false ? <GamePage /> : <LandingPage />}
    </div>
  )
}

const RouterHandler = () => {
  const { lastMessage, readyState, sendMessage } = useWebSocketContext();

  return (
    <div>

      {(readyState === 1 && lastMessage) && (
          JSON.stringify(lastMessage)
      )}
    </div>
  );
};

export default RouterHandler;
