import React from 'react';
import './BoggleLetter.css'; // Assuming the styles are stored in a separate CSS file

const BoggleLetter = ({ letter }) => {
    return (
      <div className="cube">
        <div>
          <span>{letter}</span>
        </div>
      </div>
    );
  };

export default BoggleLetter;
