import React from 'react';
import BoggleLetter from './BoggleLetter';
import './BoggleLetter.css';

const BoggleGrid = ({letters, sendMessage, rotation, timeLeft, gameID}) => {

  const restart_message = () => {
    sendMessage(JSON.stringify({ type: 'USER_INPUT', data: { gameID: gameID, input: 'restart' } }));
  }
  return (
    <div className="grid-container">
      {letters.map((letter, index) => (
        <BoggleLetter key={index} letter={letter} />
      ))}
      <div className="boggle-button" onClick={restart_message}>Restart</div>
      <div className="timer">{timeLeft.toFixed(1)}</div>
      <div className="boggle-button">Rotate</div>
    </div>
  );
};

export default BoggleGrid;
