
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { WebSocketProvider } from './components/WebSocketContext';

import RouterHandler from './components/RouterHandler';

const BogglePage = () => {

    return (
    <WebSocketProvider >
        <Routes>
        <Route path="/:gameID" element={<RouterHandler />} />
        {/*<Route path="/" element={<RouterHandler gameID={generatedGameID}/>} />*/}
        </Routes>
    </WebSocketProvider>
    );
}

export default BogglePage;