// RouterHandler.js
import React, { useEffect, useState } from 'react';
import { useWebSocketContext } from './WebSocketContext';
import BoggleGrid from './BoggleGrid';
import { Route, Routes, useParams } from 'react-router-dom';

const uuidv4 = require('uuid').v4;
const RouterHandler = ({gameID}) => {
  const { lastMessage, readyState, sendMessage } = useWebSocketContext();
  const [ letters, updateLetters ] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y']);
  const [rotation, setRotation] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerIntervalID, setTimerIntervalID] = useState(null);


  gameID = useParams();
  const generatedGameID = gameID.gameID || uuidv4();
  useEffect(() => {
    if (readyState === 1) {
      // Generate a UUID for gameID if it's not present
      
      sendMessage(JSON.stringify({ type: 'CONNECT', data: { gameID: generatedGameID, gameType: 'boggle' } }));
    }
  }, [readyState]);

  useEffect(() => {
    if (readyState === 1 && lastMessage) {
      const message = JSON.parse(lastMessage.data);
      console.log('Message Received:', message);
      if (message.type === 'UPDATE') {
        clearInterval(timerIntervalID);
        updateLetters(message.data.gameState.letters);
        if(message.data.gameState.timePaused == 0) {
          const timerID = setInterval(() => {
            const timerVal = (message.data.gameState.timeLeft - (new Date().getTime() - message.data.gameState.timeStart))/1000;
            if(timerVal > 0) {
              setTimeLeft(timerVal);
            } else {
              setTimeLeft(0);
              clearInterval(timerID);
            }
          }, 100);
          setTimerIntervalID(timerID);
        } else {
          setTimeLeft(message.data.gameState.timeLeft/1000);
        }
      }
    }
  }, [lastMessage]);

  return (
    <div>
        <div className="boggle-container">
          <BoggleGrid letters={letters} sendMessage={sendMessage} rotation={rotation} timeLeft={timeLeft} gameID={generatedGameID}/>
        </div>
    </div>
  );
};

export default RouterHandler;
