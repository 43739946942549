// TrivialPursuitPage.js
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { WebSocketProvider } from './components/WebSocketContext';

import RouterHandler from './components/RouterHandler';

const uuidv4 = require('uuid').v4;
function TrivialPursuitPage() {
  const { gameID } = useParams();

  // Generate a UUID for gameID if it's not present
  const generatedGameID = gameID || uuidv4();

  return (
    <WebSocketProvider gameID={generatedGameID}>
      <Routes>
        <Route path="/:gameID" element={<RouterHandler />} />
        <Route path="/" element={<RouterHandler />} />
      </Routes>
    </WebSocketProvider>
  );
}

export default TrivialPursuitPage;
