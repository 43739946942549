// WebSocketContext.js
import React, { createContext, useContext } from 'react';
import useWebSocket from 'react-use-websocket';

const WebSocketContext = createContext();

export function WebSocketProvider({ children, gameID }) {
  const options = {
    onOpen: () => console.log('WebSocket is open.'),
    shouldReconnect: (closeEvent) => true, // Control reconnection behavior
  };

  const webSocket = useWebSocket(process.env.REACT_APP_WEBSOCKET_API_URL, options);

  return (
    <WebSocketContext.Provider value={{ ...webSocket, gameID }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocketContext() {
  return useContext(WebSocketContext);
}
